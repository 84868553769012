<div class='container-fluid mb-3'>
  <div class='row'>
    <div class='col-12'>
      <div class='page-title mb-2 pb-3 pt-3'>
        <h3 class='mb-0'>Survey Main</h3>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading; else contentSection">
    <con-loader></con-loader>
  </ng-container>
  <ng-template #contentSection>
    <div class='row'>
      <div class='col-12'>
        <div class="d-flex justify-content-start sticky-header non-sticky py-2">
          <h3 class="mb-0">
            <a routerLink="../../../../entity/company/{{company?.id}}"><span class="badge badge-dark">{{company?.name}}</span></a>
            <span *ngIf="company?.website"><a [href]=company?.website class="link-fontsize" target="_blank">{{getStripedWebsiteString(company?.website)}}</a></span>
          </h3>
          <div class="chip chip-inverse ml-auto">
            <con-avatar [userId]="company?.manager?.id" [size]="32"></con-avatar>
            {{company?.manager?.first_name}} {{company?.manager?.last_name}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <con-no-data *ngIf="surveyEntity?.locked" count='0' class='approved-survey'></con-no-data>
      <div>
         <con-custom-taxonomy [surveyEntity]="surveyEntity"></con-custom-taxonomy>
      </div>
    </div>
  </ng-template>
</div>
